﻿@import "../../../helpers/_imports.scss";

.u-dialog--job-day-booking-totals
{
	.close
	{
		display: block;
	}

	table
	{
		col
		{
			&:nth-child(1)
			{
				width: 40%;
			}

			&:nth-child(2)
			{
				width: 60%;
			}
		}
	}

	section
	{
		margin-top: rem(32px);

		&:first-child
		{
			margin-top: 0;
		}
	}
}
