﻿@import "../helpers/_imports.scss";

// Blazor error message
.validation-message
{
	color: $color-danger;
}

.form-control
{
	&.invalid, // Blazor error
	&.input-validation-error // Server error
	{
		border-color: $color-danger !important;
	}
}

// Server error message
.field-validation-error
{
	display: block;
	font-size: rem(14px);
}

.valid.modified:not([type=checkbox])
{
}

.custom-radio-lg
{
	margin-bottom: rem(5px);

	.custom-control-label
	{
		padding-left: rem(8px);

		&::before,
		&::after
		{
			width: rem(20px);
			height: rem(20px);
			top: rem(1px);
		}
	}
}

.custom-control
{
	.custom-control-input
	{
		&:checked
		{
			& + .custom-control-label
			{
				&::before,
				&::after
				{
				}

				&::after
				{
				}
			}
		}
	}

	.custom-control-label
	{
		&::before,
		&::after
		{
		}
	}
}

textarea.form-control
{
	min-height: rem(150px);
}

.form-group
{
	.form-text
	{
		margin-bottom: rem(4px);
	}

	&--buttons
	{
		display: flex;
		margin-top: rem(8px);

		button,
		a
		{
			flex: 1 0;
			margin-left: rem(10px);

			&:first-child
			{
				margin: 0;
			}
		}
	}
}

.btn
{
	white-space: nowrap;

	&.btn-light
	{
		color: $color-primary;
		background: $color-light1;
		border: rem(1px) solid $color-primary;
		@include font-weight(semi-bold);

		&:hover
		{
			background: none;
			color: $color-light1;
			border: rem(1px) solid $color-light1;
		}
	}

	&-primary-alt
	{
		@extend .btn-primary;
		background-color: $color-primary-alt;
	}

	&-success
	{
		color: $color-light1;

		&:hover,
		&:active,
		&:focus
		{
			color: $color-light1 !important;
		}
	}
}

.input-group-text
{
	&.fas
	{
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

input[type="number"]
{
	-webkit-appearance: textfield !important;
	-moz-appearance: textfield !important;
	appearance: textfield !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
	-webkit-appearance: none;
}

.form-options
{
	.u-radio,
	.u-checkbox
	{
		margin: rem(12px 0 0);
	}
}

.custom-select--pink
{
	background: $color-primary-alt;
	color: $color-light1;
}

.search-dropdown
{
	position: relative;

	&::after
	{
		@include fa-font-icon($fa-var-caret-down);
		font-weight: bold;
		font-size: rem(32px);
		color: $color-primary;
		position: absolute;
		right: rem(32px);
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
	}

	&--small
	{
		&::after
		{
			font-size: rem(32px);
			right: rem(10px);
			color: $color-light1;
			opacity: 0.75;
		}
	}
}
