﻿@import "./helpers/_imports.scss";

html
{
	position: relative;
	height: 100%;
	font-size: 12px;

	@include media-breakpoint-up(md)
	{
		font-size: $rem-baseline;
	}
}

body
{
	overflow-x: hidden;
	background: $color-background-alt;
}

app
{
	position: relative;
	display: flex;
	flex-direction: column;
}

.brand-logo
{
	@include brand-logo($color-light1);
	display: inline-block;
}

h1
{
	@include font-weight(semi-bold);
	font-size: $font-size-h1;
	color: $color-dark1;
}

h2
{
	@include font-weight(semi-bold);
	font-size: $font-size-h2;
	color: $color-dark1;
}

h3
{
	@include font-weight(semi-bold);
	font-size: $font-size-h3;
	color: $color-dark1;
}

h4
{
	@include font-weight(semi-bold);
	font-size: $font-size-h4;
	color: $color-dark1;
}

h5
{
	@include font-weight(semi-bold);
	font-size: $font-size-h5;
	color: $color-dark1;
}

.back-link
{
	font-size: rem(10px);
	color: $color-dark3;
	display: flex;
	align-items: center;
	@include font-weight(semi-bold);

	&::before
	{
		@include fa-font-icon($fa-var-chevron-left);
		margin-right: rem(6px);
	}

	&:hover
	{
		text-decoration: none;
	}
}

.gutter-bottom
{
	margin-bottom: rem(18px);

	@include media-breakpoint-up(md)
	{
		margin-bottom: rem(30px);
	}
}
