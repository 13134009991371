﻿@import "../helpers/_imports.scss";

.modal-backdrop
{
}

.modal-content
{
	border: none;
	box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.2);
	border-radius: rem(15px);
}

.modal-header
{
	border: none;
	justify-content: center;
	padding: rem(24px 24px 12px);

	.close
	{
		display: none;
	}
}

.modal-body
{
	padding: rem(12px 24px);
}

.modal-title
{
	@include font-weight(semi-bold);
	font-size: rem(18px);
}

.modal-footer
{
	border: none;
	justify-content: center;
	padding: rem(9px 24px 24px);
}
