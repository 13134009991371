﻿@import "../helpers/_imports.scss";

.cookie-consent
{
	height: rem(90px);
	padding: rem(20px);
	border: none;
	border-radius: 0;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100vw;
	z-index: 2000;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-breakpoint-up(md)
	{
		height: rem(60px);
	}

	&__buttons
	{
		display: flex;
		flex-direction: column;

		@include media-breakpoint-up(md)
		{
			flex-direction: row;
		}
	}

	.container
	{
		display: flex;
		align-items: center;
		justify-content: space-between;

		.accept-policy,
		.decline-policy
		{
			position: static;
			padding-right: 0;
			font-size: rem(16px);
		}
	}
}
