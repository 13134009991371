﻿@import "../../../helpers/_imports.scss";

.u-dialog--job-day-booking-reviews
{
	table
	{
		col
		{
			&:nth-child(1)
			{
				width: 30%;
			}

			&:nth-child(2)
			{
				width: 70%;
			}
		}
	}
}
