﻿@import "../helpers/_imports.scss";

.navbar
{
	padding: rem(18px 12px);

	&-brand
	{
		margin: 0;
		padding: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		&__logo
		{
			@include brand-logo($color-light1, 0.4);
		}
	}
}

.main
{
	flex-grow: 1;
	padding-bottom: rem(36px);
	width: 100vw;

	.top-row
	{
		height: rem(55px);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background: $color-light1;
		width: 100%;
		padding: rem(0 12px);
	}

	.top-row > a
	{
		white-space: nowrap;
		margin-left: 1.5rem;
		color: $color-dark3;
		@include font-weight(semi-bold);
		font-size: rem(12px);
	}

	.top-row a:first-child
	{
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.sidebar
{
	background: $color-primary;

	.fas
	{
		width: 2rem;
		font-size: 1.1rem;
		vertical-align: text-top;
		top: -2px;
	}

	.nav
	{
		padding: rem(0 16px);
	}

	.nav-header
	{
		margin: rem(12px 0 0);
		text-transform: uppercase;
		color: rgba(255,255,255,0.58);
		font-size: rem(10px);

		&:first-of-type
		{
			margin: 0;
		}
	}

	.nav-item
	{
		margin: rem(12px 0 0);

		&:first-of-type
		{
			margin: 0;
		}

		&:last-of-type
		{
			margin-bottom: rem(12px);
		}

		a
		{
			color: $color-light2;
			border-radius: 5px;
			height: rem(36px);
			display: flex;
			align-items: center;
			line-height: rem(36px);
			@include font-weight(semi-bold);
			white-space: nowrap;
			font-size: rem(12px);

			&.active
			{
				background-color: $color-primary-alt;
				color: $color-light2;
			}

			&:hover
			{
				background-color: $color-primary-alt;
				color: $color-light2;
			}
		}

		.unread-message-count
		{
			background: $color-danger;
			color: $color-light1;
			min-width: rem(18px);
			height: rem(18px);
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: auto;
			border-radius: rem(100px);
		}
	}
}

.content
{
	padding: rem(18px);
}

.navbar-toggler
{
	background-color: rgba(255, 255, 255, 0.1);
	position: absolute;
}

@include media-breakpoint-down(md)
{
	.main .top-row
	{
		justify-content: space-between;
	}

	.main .top-row a, .main .top-row .btn-link
	{
		margin-left: 0;
	}
}

@include media-breakpoint-up(lg)
{
	$sidebarWidth: 230px;

	app
	{
		flex-direction: row;
	}

	.sidebar
	{
		width: $sidebarWidth;
		min-width: $sidebarWidth;
		height: 100vh;
		position: sticky;
		top: 0;
		z-index: 1000;
		overflow-y: auto;
		overflow-x: hidden;

		@include scrollbar();
	}

	.main
	{
		width: calc(100vw - #{$sidebarWidth});

		> .top-row
		{
			position: sticky;
			top: 0;
			z-index: 100;
			padding: rem(0 32px);
		}

		> .content
		{
			padding: rem(36px 48px);
		}
	}

	.navbar-toggler
	{
		display: none;
	}

	.sidebar .collapse
	{
		// Never collapse the sidebar for wide screens
		display: block;
	}
}

@include media-breakpoint-up(xl)
{
	.main > .content
	{
		padding: rem(36px 96px);
	}
}
