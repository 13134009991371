﻿@import "../helpers/_imports.scss";

footer
{
	.unauthenticated-page &
	{
		color: $color-light1;
		@include font-weight(bold);
		text-align: center;
		height: $footerHeight;
		display: flex;
		justify-content: center;
		align-items: center;
		background: $color-primary;
		width: 100%;

		@include media-breakpoint-up(md)
		{
			position: sticky;
			left: 0;
			bottom: 0;
		}
	}
}
