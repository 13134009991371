﻿@import "../helpers/_imports.scss";

#blazor-error-ui
{
	background: $color-danger;
	color: $color-light1;
	bottom: 0;
	box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);
	display: none;
	left: 0;
	padding: 0.6rem 1.25rem 0.7rem 1.25rem;
	position: fixed;
	width: 100%;
	z-index: 1000;
}

#blazor-error-ui .dismiss
{
	cursor: pointer;
	position: absolute;
	right: 0.75rem;
	top: 0.5rem;
}