﻿@import "../helpers/_imports.scss";

.splash-screen
{
	background: $color-background;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;

	&__logo
	{
		@include brand-logo($color-light1, 0.75);

		@include media-breakpoint-up(md)
		{
			@include brand-logo($color-light1);
		}
	}

	&__loading-icon
	{
		margin-top: rem(16px);

		&::after
		{
			@include fa-font-icon($fa-var-spinner);
			animation: fa-spin 2s infinite linear;
			font-size: rem(48px);
			color: $color-light1;
		}
	}
}
