﻿@import "../helpers/_imports.scss";

.results-page
{
	$self: &;

	h1
	{
		margin-top: rem(26px);
	}

	h2
	{
		font-size: rem(16px);
		@include font-weight(regular);
	}

	&__header
	{
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		@include media-breakpoint-up(md)
		{
			flex-direction: row;
		}
	}

	&__results
	{
		margin-top: rem(50px) !important;

		&--actions
		{

			#{$self}__item
			{
				padding: rem(24px 88px 24px 24px);
			}
		}
	}

	&__item
	{
		$self: &;

		box-shadow: rem(0px 1px 10px) rgba(0, 0, 0, 0.2);
		border-radius: rem(15px);
		min-height: rem(100px);
		padding: rem(24px);
		background: $color-light1;
		position: relative;
		text-decoration: none;
		display: block;

		&:hover
		{
			text-decoration: none;
		}

		&-container
		{
			margin-top: rem(24px);

			&:first-child
			{
				margin-top: 0;
			}
		}

		&--horizontal
		{
			display: flex;
			padding-left: 0 !important;

			#{$self}__details
			{
				padding-left: rem(12px);

				@include media-breakpoint-up(md)
				{
					padding-left: rem(24px);
				}
			}

			#{$self}__image
			{
				margin: rem(-24px 0);

				.u-dynamic-image
				{
					border-radius: rem(15px 0 0 15px);
					overflow: hidden;
					display: flex;
					align-items: center;
					height: 100%;

					img
					{
						width: 100%;
						height: 100%;
						object-fit: contain;
					}

					width: rem(100px);

					@include media-breakpoint-up(sm)
					{
						width: rem(150px)
					}

					@include media-breakpoint-up(md)
					{
						width: rem(175px)
					}
				}

				&--padding
				{
					.u-dynamic-image
					{
						padding: rem(6px);

						@include media-breakpoint-up(md)
						{
							padding: rem(12px);
						}
					}
				}
			}
		}

		&__heading
		{
			font-size: rem(18px);
			@include font-weight(semi-bold);
			text-transform: uppercase;
			color: $color-primary;
		}

		&__subheading
		{
			font-size: rem(14px);
			@include font-weight(regular);
			text-transform: uppercase;
			color: $color-dark2;
		}

		&__text
		{
			color: $color-dark2;
		}

		&__link
		{
			color: $color-primary;
			@include font-weight(semi-bold);
		}

		&__navigate-button
		{
			position: absolute;
			right: rem(12px);
			top: 50%;
			transform: translateY(-50%);

			@include media-breakpoint-up(md)
			{
				right: rem(24px);
			}

			&-link
			{
				display: block;
				width: rem(40px);
				height: rem(40px);
				background: $color-primary;
				color: $color-light1;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: rem(20px);

				&::before
				{
					@include fa-font-icon($fa-var-angle-right);
					font-weight: 300;
					font-size: rem(30px);
				}

				&:hover
				{
					text-decoration: none;
					color: $color-light1;
				}
			}
		}
	}

	.u-grid
	{
		&__caption
		{
			font-size: rem(14px);
			margin-top: rem(24px);
		}
	}

	.u-pagination
	{
		margin-top: rem(24px);
	}

	&__actions
	{
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		margin-top: rem(24px);
	}
}
