﻿@import "../helpers/_imports.scss";

.manage-account-page
{
	&__content
	{
		border-radius: $border-radius-lg;
		background: $color-light1;
		padding: rem(24px);
		margin-top: rem(48px);

		h4
		{
			@include font-weight(semi-bold);
			font-size: rem(15px);
			margin-bottom: rem(12px);
		}

		.btn
		{
			flex: 0;
			padding: rem(6px 24px);
		}
	}
}