﻿// Colors
$color-dark1: #2D2D2D;
$color-dark2: #70757e;
$color-dark3:#434343;

$color-light1: #fff;
$color-light2: #F3F5F8; // Fog
$color-light3: #D5D9DE; // Slate

$color-primary: #387ADD;
$color-primary-alt: #1E5BB7; // Secondary/Blue
$color-secondary: #70757e;
$color-secondary-alt: #CDDEF6;
$color-success: #5CE0D8;
$color-success-alt: #31D2C9;
$color-danger: #EB6665;
$color-info: $color-primary;
$color-warning: #EBD665;

$color-background: $color-primary;
$color-background-alt: #E5E5E5;

// Sizes - NB: The $rem-baseline is used by the rem plugin to calculate values
$rem-baseline-raw: 14;
$rem-baseline: 14px;

$font-size-h1: 1.5rem;
$font-size-h2: 1.3rem;
$font-size-h3: 1.2rem;
$font-size-h4: 1.1rem;
$font-size-h5: 1rem;

// Font
$font-family-heading: "Inter", sans-serif;
$font-family-body: "Inter", sans-serif;
$font-weights: ("regular": 400, "medium": 500, "semi-bold": 600, "bold": 700);

$border-radius: rem(5px);
$border-radius-lg: rem(5px);
$border-radius-sm: rem(5px);

$footerHeight: rem(70px);